<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">

        </v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar color="secondary">
                        <v-toolbar-title class="pa-3 white--text text-h5">
                            Referral Settings
                        </v-toolbar-title>
                    </v-toolbar>

                    <div v-if="referral!=null" class="my-4 pa-4">
                        <div class="d-flex justify-content-start">
                            <v-select :items="referralEnableOptions" v-model="referral.enable_referral" value="value" name="text" label="Referral Program Status "
                            dense outlined >

                            </v-select>
                        </div>
                        <div class="d-flex justify-content-start">
                            <v-text-field v-model="referral.referral_points" type="number" label="Referral points" dense outlined>

                            </v-text-field>
                        </div>
                        <div class="d-flex justify-content-start my-2">
                                <v-select :items="referralEnableOptions" v-model="referral.referrer_user" value="value" name="text" label="Referrer receive reward"
                                dense outlined
                                persistent-hint
                                hint=" ** Referrer user means the existing user who refer the new user">
                                </v-select>
                        </div>
                        <div class="d-flex justify-content-start my-2">
                            <v-text-field
                                v-model="referral.share_link"
                                label="Share Link"
                                dense
                                outlined>

                            </v-text-field>
                        </div>
                        <!-- <div class="d-flex justify-content-start">

                        </div> -->
                        <div class="d-flex justify-content-start">
                            <v-select :items="referralEnableOptions" v-model="referral.referred_user" dense outlined value="value" name="text" 
                            persistent-hint hint = "** Referred user means the new user that register using the referral link" label="Referred receive reward">
                            </v-select>
                        </div>
                        <!-- {{ item.term_and_condition }} -->
                        <div class="d-flex justify-content-start">
                            <v-file-input
                                v-model="term_and_condition"
                                label="Upload Term and Condition"
                                outlined
                                dense>

                            </v-file-input>

                        </div>
                        <div v-if="referral.term_and_condition!=null" class="d-flex justify-content-start">
                            <span>
                            </span>
                            <a :href="referral.term_and_condition" target="_blank" download>
                                Existing Term and Condition
                            </a>
                        </div>
                        <div class="d-flex justify-end mt-5">
                            <v-btn color="primary" plain @click="()=>{
                                $router.go(-1); 
                            }">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" :loading="api.isLoading" @click="validateInput">
                                Confirm
                            </v-btn>
                        </div>
                    </div>



                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        referral:null,
        isPending:false,
        term_and_condition:null,
        referralLink:null,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
        referralEnableOptions:[
            {
                text:'Enabled',
                value:"1",
            },
            {
                text:'Disabled',
                value:"0",
            },
        ]
        
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getReferralSettings"){
                this.referral = resp.data; 
            }
            if(resp.class==="updateReferralSettings"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update the referral settings";
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.api.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchReferralSettingsApi = this.fetchReferralSettings();
            this.$api.fetch(fetchReferralSettingsApi);
        },
        fetchReferralSettings(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/settings";
            return tempApi;  
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateReferralSettingsApi = this.updateReferralSettings();
            this.$api.fetch(updateReferralSettingsApi);
            
        },
        updateReferralSettings(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/settings/"+this.referral.id;
            let formData = new FormData;
            formData.append('enable_referral',this.referral.enable_referral);
            formData.append('referral_points',this.referral.referral_points);
            formData.append('referrer_user',this.referral.referrer_user);
            formData.append('referred_user',this.referral.referred_user);
            formData.append('share_link',this.referral.share_link);
            if(this.term_and_condition!=null){
                formData.append('term_and_condition',this.term_and_condition);
            }
            tempApi.params = formData;
            return tempApi;
        },
        cancelSubmit(){
            this.isPending = true;
        },
        create(){

        },
    }
}
</script>